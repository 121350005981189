import EnhancedComponent from "./EnhancedComponent";

const BaseLayout = (WrappedComponent, children, topBorder = true, sideBorder = true, onlyMenu=false, containerSx={}) => {
  return function (props) {
    return <EnhancedComponent {...props} WrappedComponent={WrappedComponent} containerSx={containerSx} children={children} topBorder={topBorder} sideBorder={sideBorder} onlyMenu={onlyMenu}/>
  };
}

export default BaseLayout;

